import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  DateTimeInput,
  AutocompleteArrayInput,
  FileField,
  ImageField,
  ImageInput,
} from "react-admin";
import {
  mediaDurations,
  mediaReviewStati,
  mediaStati,
  mediaTags,
  mediaTypes,
  teachers,
  difficulties,
} from "../../data/common";
import { Divider, Typography } from "@mui/material";

const MediaEdit = (props) => {
  return (
    <Edit
      title="Edit Media"
      {...props}
      mutationMode="pessimistic"
      redirect={false}
    >
      <SimpleForm>
        <TextInput disabled source="id" />
        {/* <TextInput source="author_id" /> */}
        <SelectInput source="author_id" choices={teachers} />
        <SelectInput source="difficulty" choices={difficulties} />
        <TextInput
          source="background_image"
          fullWidth
          helperText="See Audiobgs on the left"
        />
        <TextInput
          source="background_image_large"
          fullWidth
          helperText="NEW(2025) high res 1200x1200 bg image"
        />

        <TextInput
          source="logo_image"
          fullWidth
          helperText="Only for sounds/music"
        />

        <TextInput
          source="card_image"
          fullWidth
          helperText="NEW(2025) image for use on the card"
        />

        <TextInput source="content_link" fullWidth />
        <FileField source="content_link" title="Download Audio File" download />
        <TextInput source="title" fullWidth />
        <TextInput source="description" fullWidth />
        <TextInput source="featured_artist" fullWidth />
        <TextInput source="featured_image" fullWidth />
        <TextInput source="featured_song" fullWidth />
        <BooleanInput source="featured" />
        <NumberInput source="intro_len_seconds" />
        <DateTimeInput source="live_at" />
        <SelectInput source="status" choices={mediaStati} />
        <SelectInput source="duration" choices={mediaDurations} />
        <AutocompleteArrayInput source="tags" choices={mediaTags} />
        <SelectInput source="type" choices={mediaTypes} />
        <Divider flexItem />
        <Typography variant="h5">Content Review</Typography>
        <SelectInput source="status_review" choices={mediaReviewStati} />
        <DateTimeInput disabled source="last_review" />
        <TextInput disabled source="last_review_by" fullWidth />
        <TextInput source="staff_notes" multiline fullWidth />
        <TextInput source="transcript" multiline fullWidth />
        <NumberInput source="rank" />
        <NumberInput source="plays" />
        <TextInput source="class_type" />
        <ImageInput
          source="waveform_images"
          label="WaveForm Images"
          accept="image/*"
          multiple
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <Divider flexItem />
        <Typography variant="h5">Read Only Fields</Typography>
        <TextInput
          disabled
          source="created"
          helperText="When this was added to the database"
        />
        <TextInput
          disabled
          source="created_orig"
          helperText="Roughly when production was done with this item as that could be different from when it was added to the data base"
        />
        <TextInput
          disabled
          source="live_at_orig"
          helperText="When this originally went live as that could be different from current live_at date since we recycle tracks"
        />
        <TextInput
          disabled
          source="updated"
          helperText="Last time an edit was made to this item"
        />
      </SimpleForm>
    </Edit>
  );
};

export default MediaEdit;
