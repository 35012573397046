import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import { emailFromIDs, emailTypes } from "../../data/common";

const MailmsgCreate = (props) => {
  return (
    <Create title="Send a test email" {...props}>
      <SimpleForm>
        {/* <TextInput source="subject" fullWidth />
        <TextInput source="body" fullWidth />
        <SelectInput source="from_id" choices={emailFromIDs} /> */}
        <TextInput
          source="user_id_to"
          fullWidth
          helperText="warrenlocal:hckrvsahxai63bojraq3zs72na warrendev:tgooudjddmi63bw2ctskwua23i warprod:qlj5laoscmi6znncplz3ufdv4i reydev:uzkvxwiv4ui65mwy6646y6cese reyprod:kd557we464i63efpc7kfum3lea aliprod:dftmao73iii63ncjjtvw2e7y3u"
        />
        <TextInput
          source="lang"
          helperText="de, en, es, fr, it, ja, pl, pt, ru, sv, tl, zh"
        />
        <SelectInput source="type" choices={emailTypes} />
        {/* <NumberInput source="template_id" fullWidth helperText="38491948" /> */}
      </SimpleForm>
    </Create>
  );
};

export default MailmsgCreate;
