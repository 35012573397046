import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  NumberField,
  FunctionField,
  BooleanField,
  WithRecord,
  Link,
  ReferenceField,
} from "react-admin";
import { Divider } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { webAppBaseURL, webFlappBaseURL } from "../../utils/utils";

const nightmareSleepHomeLinks = () => {
  return [
    {
      urlPath: "/sleep-today",
      name: "Sleep Home Page",
      hash: "",
    },

    {
      urlPath: "/sleep-today",
      name: "Journals",
      hash: "#journals",
    },
    {
      urlPath: "/sleep-today",
      name: "Morning Journal",
      hash: "#morning-journal",
    },
    {
      urlPath: "/sleep-today",
      name: "Evening Journal",
      hash: "#evening-journal",
    },

    {
      urlPath: "/sleep-today",
      name: "Sleep Home - Sessions",
      hash: "#sessions",
    },

    {
      urlPath: "/sleep-today",
      name: "Sleep Home - Sounds",
      hash: "#sounds",
    },

    {
      urlPath: "/sleep-today",
      name: "Essentials",
      hash: "#essentials",
    },

    {
      urlPath: "/sleep-today",
      name: "Lessons",
      hash: "#lessons",
    },

    {
      urlPath: "/sleep-today",
      name: "Coach",
      hash: "#coach",
    },
  ];
};
const nightmareDaytimePageLinks = () => {
  return [
    {
      urlPath: "/daytime-support",
      name: "Daytime Page",
      hash: "",
    },
    {
      urlPath: "/daytime-support",
      name: "Daytime Essentials",
      hash: "#daytime-essentials",
    },
    {
      urlPath: "/daytime-support",
      name: "Daytime Sessions",
      hash: "#daytime-sessions",
    },
  ];
};
const nightmareOtherPageLinks = () => {
  return [
    {
      urlPath: "/content/",
      post: "posts/tips/consistent-schedule",
      name: "Sleep Essential Page",
      hash: "",
    },
    {
      urlPath: "/content/",
      post: "posts/day-essentials/how-to-set-effective-goals",
      name: "Daytime Essential Page",
      hash: "",
    },
    {
      urlPath: "/content/",
      post: "posts/problem-with-natural-sleep-aids",
      name: "Other Post Page",
      hash: "",
    },
    {
      urlPath: "/lesson-detail/lesson9",
      name: "Lesson 9 Detail Page",
      hash: "",
    },
    {
      urlPath: "/player/75unbulckui63mqszbiiziibrq",
      name: "Audio Player Fullscreen Page",
      hash: "",
    },
  ];
};

const NightmareLinksMap = (record, linksFn) =>
  linksFn().map(({ urlPath, name, hash, post }) => (
    <div style={{ marginTop: 5 }}>
      <a
        href={
          webAppBaseURL() +
          urlPath +
          `?lt=${record.auth_token14}${post ? "&link=" + post : ""}${
            hash ? hash : ""
          }`
        }
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
    </div>
  ));

const FlappAppLink = (urlPath, at, linkName) => (
  <div style={{ marginTop: 5 }}>
    <a
      href={webFlappBaseURL() + urlPath + `?at=${at}`}
      target="_blank"
      rel="noreferrer"
    >
      {linkName}
    </a>
  </div>
);

const UserShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ "& .RaLabeled-label": { fontWeight: "bold" } }}
      >
        <FunctionField
          label="Send Coach Message"
          render={(record) => (
            <Link to={`/coachusers/${record.id}`}>
              Click here to send sleep coach message
            </Link>
          )}
        />

        <FunctionField
          label="Login Webapp Using Auth Token (at=...)"
          render={(record) => {
            return (
              <div>
                <h4>NEW Flutter Webapp Links</h4>
                {FlappAppLink(
                  "/#/home",
                  record.auth_token14,
                  "Sleep Home Page"
                )}
                <h4>Sleep Home/Today Page</h4>
                {NightmareLinksMap(record, nightmareSleepHomeLinks)}
                <h4>Daytime Page</h4>
                {NightmareLinksMap(record, nightmareDaytimePageLinks)}
                <h4>Other Page Links</h4>
                {NightmareLinksMap(record, nightmareOtherPageLinks)}
              </div>
            );
          }}
        />

        <TextField source="challenge_id" />
        <TextField source="chat_link" />
        <TextField source="chat_username" />
        <DateField source="created" showTime={true} />
        <TextField source="email" />
        <TextField source="email_sha1" />
        <DateField source="email_verified" showTime={true} />
        <ArrayField source="feature_flags" />
        <ReferenceField source="group" reference="groups" />

        <TextField source="division" />
        <TextField source="department" />

        <TextField source="id" />
        <TextField
          source="initial_app"
          label="Initial App (they used to sign up)"
        />

        <DateField source="last_login" showTime={true} />
        <DateField source="last_magic_email_sent" showTime={true} />
        <DateField source="last_magic_check" showTime={true} />

        <NumberField source="log_lesson_day" />

        <TextField source="magic_link_code" />
        <TextField
          source="auth_token"
          sx={{
            wordBreak: "break-all",
            maxWidth: "80%",
            // Alternatively: overflowWrap: 'break-word'
          }}
        />
        <TextField
          source="auth_token14"
          label="Auth Token that expires in 14 days"
          sx={{
            wordBreak: "break-all",
            maxWidth: "80%",
            // Alternatively: overflowWrap: 'break-word'
          }}
        />
        <TextField source="nickname" />
        <TextField source="status" />
        <TextField source="staff_status" label="Staff" />
        <FunctionField
          label="Staff Notes"
          render={(record) => (
            <div style={{ whiteSpace: "pre-wrap" }}>{record.staff_notes}</div>
          )}
        />
        {/* <TextField source="staff_notes" /> */}
        <TextField source="timezone" />
        <DateField source="updated" showTime={true} />

        <CurrentChallenge />
        <UserSettings />
        <UserDevices />
        <h2>Daily Logs</h2>

        <ArrayField source="logs">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" reference="logs" link="show" />
            <TextField source="day" />
            <FunctionField
              label="InBed / Asleep"
              render={(record) =>
                `${record.in_bed_hour}:${record.in_bed_min} / ${record.asleep_hour}:${record.asleep_min}`
              }
            />
            <FunctionField
              label="Wake / OutOfBed"
              render={(record) =>
                `${record.wake_up_hour}:${record.wake_up_min} / ${record.out_of_bed_hour}:${record.out_of_bed_min}`
              }
            />
            <FunctionField
              label="WakeUp Times / Duration "
              render={(record) =>
                `${record.wake_up_times} / ${record.wake_up_duration}`
              }
            />
            <NumberField label="Total Sleep Time" source="asleep_duration" />
            <BooleanField
              source="sleep_log_confirmed"
              label="Sleep Confirmed?"
            />
            <NumberField source="session_seconds" label="Session Secs" />
            <NumberField source="lesson_day" label="Lesson Day" />
            <NumberField
              source="lesson_items_completed"
              label="Lesson Items Done"
            />
            <NumberField source="log_percent" label="Log %" />
            <NumberField source="lesson_percent" label="Lesson %" />
            <NumberField source="session_percent" label="Session %" />
          </Datagrid>
        </ArrayField>

        <h2>Daily SLogs (old)</h2>

        <ArrayField source="slogs">
          <Datagrid bulkActionButtons={false}>
            <TextField source="day" />
            <TextField source="progress_yesterday_choice" label="Progress" />
            <TextField source="irritable_choice" label="Irritable" />
            <TextField source="get_in_bed_choice" label="Get in bed" />
            <TextField source="fall_asleep_choice" label="Fall asleep" />
            <TextField source="middle_night_wakeup_choice" label="Wake Ups" />
            <TextField source="time_awake_choice" label="Time Awake" />
            <TextField source="day" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const UserDevices = () => {
  return (
    <WithRecord
      render={(record) => {
        if (
          !record ||
          !Array.isArray(record.devices) ||
          record.devices.length === 0
        ) {
          return (
            <Card>
              <CardContent>
                <h2>User Devices</h2>
                <h3>No devices</h3>
              </CardContent>
            </Card>
          );
        }
        console.log("record.devices");

        return (
          <div>
            {record.devices.map((dev, idx) => (
              <UserDevice device={dev} idx={idx} />
            ))}
          </div>
        );
      }}
    />
  );
};

const UserDevice = (props) => {
  const { device, idx } = props;
  return (
    <Card>
      <CardContent>
        <h2>User Device {idx}</h2>
        <table>
          <DevSetTableRow title="App Version" value={device.app_version} />
          <DevSetTableRow title="App Build ID" value={device.app_build} />
          <DevSetTableRow title="Brand" value={device.brand} />
          <DevSetTableRow title="Created" value={device.created} />
          <DevSetTableRow title="Model ID" value={device.model_id} />
          <DevSetTableRow title="Device ID (PK)" value={device.id} />
          <DevSetTableRow
            title="Is Emulator?"
            value={device.is_emulator.toString()}
          />
          <DevSetTableRow
            title="Is Tablet?"
            value={device.is_tablet.toString()}
          />
          <DevSetTableRow title="Manufacturer" value={device.manufacturer} />
          <DevSetTableRow title="Name" value={device.name} />
          <DevSetTableRow
            title="Push Disabled"
            value={device.push_disabled.toString()}
          />
          <DevSetTableRow title="Type" value={device.type} />
          <DevSetTableRow title="Push Token" value={device.push_token} />
          <DevSetTableRow title="System Name" value={device.system_name} />
          <DevSetTableRow
            title="System Version"
            value={device.system_version}
          />
          <DevSetTableRow
            title="System Build ID"
            value={device.system_build_id}
          />
          <DevSetTableRow title="Updated" value={device.updated} />
          <DevSetTableRow title="User ID" value={device.user_id} />
        </table>
      </CardContent>
    </Card>
  );
};

const DevSetTableRow = (props) => {
  const { title, value } = props;
  return (
    <tr>
      <th style={{ textAlign: "left", padding: "5px" }}>{title}</th>
      <td style={{ padding: "5px" }}>{value}</td>
    </tr>
  );
};

const UserSettings = () => {
  return (
    <Card>
      <CardContent>
        <h2>User Settings</h2>
        <WithRecord
          render={(record) => (
            <table>
              {/* old/deprecated */}
              {/* <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Sleep Goal:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.sleep_goal}
                </td>
              </tr> */}
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>Language</th>
                <td style={{ padding: "5px" }}>{record.settings?.language}</td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Sleep Goal Many:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.sleep_goal_many?.join(", ")}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Nights Per Week:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.nights_per_week}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Bedtime Hour:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.bedtime_hour}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Bedtime Min:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.bedtime_min}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Wake Hour:{" "}
                </th>
                <td style={{ padding: "5px" }}>{record.settings?.wake_hour}</td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Wake Min:{" "}
                </th>
                <td style={{ padding: "5px" }}>{record.settings?.wake_min}</td>
              </tr>
              {/* <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Internal Factors:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.internal_factors?.join(", ")}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  External Factors:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.external_factors?.join(", ")}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  More Sleep Input:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.more_sleep_input}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Tried Solutions:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.tried_solutions?.join(", ")}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Trackers:{" "}
                </th>
                <td style={{ padding: "5px" }}>{record.settings?.trackers}</td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Trackers App:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.trackers_app}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Trackers Device:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.trackers_device}
                </td>
              </tr> */}
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>Sex: </th>
                <td style={{ padding: "5px" }}>{record.settings?.sex}</td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Age Range:{" "}
                </th>
                <td style={{ padding: "5px" }}>{record.settings?.age_range}</td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Schedule consistency:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.schedule_consistency}
                </td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>Snoring: </th>
                <td style={{ padding: "5px" }}>{record.settings?.snoring}</td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>Baby: </th>
                <td style={{ padding: "5px" }}>{record.settings?.baby}</td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Caffeine:{" "}
                </th>
                <td style={{ padding: "5px" }}>{record.settings?.caffeine}</td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>Env: </th>
                <td style={{ padding: "5px" }}>{record.settings?.env}</td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Mobile Device:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.mobile_device}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Intake Complete{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.intake_complete}
                </td>
              </tr>
              <hr />
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notifications Set:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_set.toString()}
                </td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notifications Re-Engagement Enabled:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_engagement.toString()}
                </td>
              </tr>
              <hr />

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notifications Bedtime Enabled:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_bedtime.toString()}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Bedtime Hour:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_bedtime_hour}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Bedtime Min:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_bedtime_min}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Bedtime Days:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_bedtime_days}
                </td>
              </tr>
              <hr />
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notifications Slog Enabled:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_log.toString()}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Slog Hour:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_slog_hour}
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Slog Min:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_slog_min}
                </td>
              </tr>

              <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Slog Days:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_slog_days}
                </td>
              </tr>
              {/* <tr>
                <th style={{ textAlign: "left", padding: "5px" }}>
                  Notification Engagement:{" "}
                </th>
                <td style={{ padding: "5px" }}>
                  {record.settings?.notification_engagement.toString()}
                </td>
              </tr> */}
            </table>
          )}
        />
      </CardContent>
    </Card>
  );
};

const CurrentChallenge = () => {
  return (
    <WithRecord
      render={(record) => {
        if (
          !record ||
          !record.challenge ||
          record.challenge.steps.length === 0
        ) {
          return (
            <Card>
              <CardContent>
                <h2>Current Challenge</h2>
                <h3>No challenge</h3>
              </CardContent>
            </Card>
          );
        }

        return <ChallengeSessionComponent challenge={record.challenge} />;
      }}
    />
  );
};

const ChallengeSessionComponent = (props) => {
  const { challenge } = props;

  const formatTime = (time) => {
    return time ? new Date(time).toLocaleString() : "N/A";
  };

  const sectionHeaderStyle = {
    background: "#f5f5f5", // Light grey background for the section header for visual separation
    paddingLeft: "20px", // Indentation for the section header
  };

  const sectionItemStyle = {
    paddingLeft: "40px", // Increased indentation for items within the section
    paddingTop: "10px", // Space between items
  };

  return (
    <Card>
      <CardContent>
        <h2>Current Challenge </h2>
        <table>
          <DevSetTableRow title="Title" value={challenge.title} />
          <DevSetTableRow title="Name" value={challenge.name} />
          <DevSetTableRow title="Type" value={challenge.type} />
          <DevSetTableRow title="Points (total)" value={challenge.points} />
          <DevSetTableRow title="Points Daily" value={challenge.points_daily} />
          <DevSetTableRow title="Description" value={challenge.description} />
          <DevSetTableRow title="Status" value={challenge.status} />
          <DevSetTableRow
            title="challenge ID (PK)"
            value={challenge.challenge_id}
          />
          <DevSetTableRow title="User ID" value={challenge.user_id} />
          <DevSetTableRow title="Duration" value={challenge.duration} />
          <DevSetTableRow
            title="Read More Link"
            value={challenge.read_more_link}
          />
          <DevSetTableRow
            title="Created"
            value={formatTime(challenge.created)}
          />
          <DevSetTableRow
            title="Updated"
            value={formatTime(challenge.updated)}
          />
          <DevSetTableRow
            title="Completed On"
            value={formatTime(challenge.completed_on)}
          />
          <DevSetTableRow
            title="Completed Text"
            value={challenge.completed_text}
          />
        </table>
        <h4>Challenge Events</h4>
        {challenge.status_events && challenge.status_events.length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Status
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Message
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Created
                </th>
              </tr>
            </thead>
            <tbody>
              {challenge.status_events.map((event, eventIdx) => (
                <tr
                  key={`event-${eventIdx}`}
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {event.status}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {event.message}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {formatTime(event.created)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No events to display.</p>
        )}

        <h4>Challenge Steps</h4>
        {challenge.steps && challenge.steps.length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Order
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Group
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Action Type
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Name
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Progress
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Progress Total
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Points (total)
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Points Daily
                </th>
                <th
                  style={{ borderBottom: "1px solid #ccc", textAlign: "left" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {challenge.steps.map((step, idx) => (
                <tr
                  key={`step-${idx}`}
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.order}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.group}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.action_type}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.name}
                  </td>

                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.progress}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.progress_total}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.points}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.points_daily}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {step.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No events to display.</p>
        )}
      </CardContent>
    </Card>
  );
};

export default UserShow;
